// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TransferForm from './TransferForm';
import FileSubmitted from './pages/FileSubmitted';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<TransferForm />} />
                <Route path="/filesubmitted" element={<FileSubmitted />} />
            </Routes>
        </Router>
    );
};

export default App;
