import React, { useEffect } from 'react';
import '../TransferForm.css';

const FileSubmitted = () => {
    useEffect(() => {
        // Push the page view event to dataLayer
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: {
                    path: '/filesubmitted',
                    title: 'Thank You - Form Submitted'
                }
            });
            
            // Push conversion event
            window.dataLayer.push({
                event: 'form_submission_success',
                formName: 'transfer_form'
            });
        }
    }, []);

    return (
        <div className="transfer-form">
            <h2>Thank You!</h2>
            <p>
                Your files have been successfully submitted! We will review your request and get back to you with a price shortly.
            </p>
            <p>
                We appreciate your patience, and we're excited to collaborate with you!
            </p>
        </div>
    );
};

export default FileSubmitted;
