import { initializeApp } from 'firebase/app';
import { getStorage, ref } from 'firebase/storage';
import { getFirestore, collection } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCiWaCuL61jr1v2xAKqJj5c_qS-4J-l6Wc",
  authDomain: "rotorockets-708a3.firebaseapp.com",
  projectId: "rotorockets-708a3",
  storageBucket: "rotorockets-708a3.appspot.com",
  messagingSenderId: "710062255030",
  appId: "1:710062255030:web:a89970264785851cc9af17",
  measurementId: "G-09Q8DY2FJQ"
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);
const db = getFirestore(app);

export { db, storage, ref, collection };
