import React, { useState, useCallback, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage, collection } from './firebase';
import { addDoc } from 'firebase/firestore';
import './TransferForm.css';
import ScissorsIcon from './assets/icons/scissors.svg';
import EraserIcon from './assets/icons/eraser.svg';
import PlaceholderImage from './assets/images/placeholder.webp'; // Ensure you have this image
import CloudUploadIcon from './assets/icons/cloud-arrow-up.svg'; // Updated to use cloud-arrow-up.svg
import { useNavigate } from 'react-router-dom';

const TransferForm = () => {
    const [files, setFiles] = useState([]); // Array of file objects with progress
    const [formValues, setFormValues] = useState({
        fullName: '',
        email: '',
        jobType: '',
        extraInfo: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [step, setStep] = useState(1);
    const [fileDetails, setFileDetails] = useState({});
    const [selectedJobType, setSelectedJobType] = useState('cleanup');
    const [isDragging, setIsDragging] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false); // New state for terms checkbox
    const navigate = useNavigate();

    const handleUpload = (event) => {
        const uploadedFiles = Array.from(event.target.files);
        addFiles(uploadedFiles);
    };

    const addFiles = useCallback((uploadedFiles) => {
        const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi', '.mkv', '.mxf', '.flv', '.wmv', '.mpeg', '.mpg', '.3gp', '.3g2', '.mts', '.m2ts', '.ts', '.vob'];
        const newFiles = uploadedFiles.filter(file => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            return file.type.startsWith('video/') || videoExtensions.includes(`.${fileExtension}`);
        });

        if (newFiles.length === 0) {
            alert('Please upload only video files.');
            return;
        }

        const filesWithProgress = newFiles.map(file => ({
            file,
            progress: 0,
            downloadURL: '',
            thumbnail: '', // Add a thumbnail property
        }));

        setFiles(prevFiles => [...prevFiles, ...filesWithProgress]);
        generateThumbnails(filesWithProgress);
        uploadFiles(filesWithProgress);
    }, []);

    const generateThumbnails = useCallback((filesToGenerate) => {
        filesToGenerate.forEach(fileObj => {
            const video = document.createElement('video');
            video.src = URL.createObjectURL(fileObj.file);
            video.addEventListener('loadeddata', () => {
                video.currentTime = 0;
            });
            video.addEventListener('seeked', () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const context = canvas.getContext('2d');
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                const thumbnail = canvas.toDataURL('image/png');
                setFiles(prevFiles =>
                    prevFiles.map(f =>
                        f.file.name === fileObj.file.name ? { ...f, thumbnail } : f
                    )
                );
                URL.revokeObjectURL(video.src);
            });
        });
    }, []);

    const uploadFiles = useCallback((filesToUpload) => {
        filesToUpload.forEach(fileObj => {
            const storageRefObj = ref(storage, `uploads/${fileObj.file.name}`);
            const uploadTask = uploadBytesResumable(storageRefObj, fileObj.file);

            uploadTask.on(
                'state_changed',
                snapshot => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setFiles(prevFiles =>
                        prevFiles.map(f =>
                            f.file.name === fileObj.file.name ? { ...f, progress } : f
                        )
                    );
                },
                error => {
                    console.error('Upload error:', error);
                    alert(`Failed to upload ${fileObj.file.name}`);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                        setFiles(prevFiles =>
                            prevFiles.map(f =>
                                f.file.name === fileObj.file.name ? { ...f, downloadURL } : f
                            )
                        );
                        console.log(`File uploaded: ${downloadURL}`);
                    });
                }
            );
        });
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleNextStep = (event) => {
        event.preventDefault();

        if (files.length === 0 || !areAllFilesUploaded()) {
            alert('Upload all your files first.');
            return;
        }

        // Remove the job type check
        setFormValues({ 
            ...formValues, 
            jobType: selectedJobType || 'not selected' // Set default if not selected
        });

        if (step === 1) {
            setStep(2);
        }
    };

    const handleFileDetailChange = (index, field, value) => {
        setFileDetails(prevDetails => ({
            ...prevDetails,
            [index]: {
                ...prevDetails[index],
                [field]: value,
            },
        }));
    };

    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    }, []);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const droppedFiles = Array.from(e.dataTransfer.files);
        addFiles(droppedFiles);
    }, [addFiles]);

    const areAllFilesUploaded = () => {
        return files.every(f => f.progress === 100);
    };

    const getNextButtonTitle = () => {
        if (!areAllFilesUploaded()) {
            return 'Please wait for all files to finish uploading.';
        }
        if (files.length === 0) {
            return 'Please upload your files first.';
        }
        return '';
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); 
        console.log('Form submission started'); // Debug log
        
        if (!formValues.fullName || !formValues.email) {
            alert('Please fill in all required fields.');
            return;
        }
    
        setIsSubmitting(true);
        console.log('Form is being submitted'); // Debug log
    
        const submission = {
            ...formValues,
            shotCount: files.length,
            files: files.map((fileObj, index) => ({
                fileURL: fileObj.downloadURL,
                title: fileObj.file.name,
                description: fileDetails[index]?.description || '',
                objectCount: 0,
            })),
            timestamp: new Date(),
        };
    
        try {
            await addDoc(collection(db, 'form_submissions'), submission);
            console.log('Form submission successful'); // Debug log
            navigate('/filesubmitted'); // Redirect to the new page
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form.');
        } finally {
            setIsSubmitting(false);
            console.log('Form submission complete'); // Debug log
        }
    };

    const handleAddMoreFiles = () => {
        document.getElementById('file-upload').click();
    };

    const handleTermsChange = (event) => {
        setIsTermsChecked(event.target.checked);
    };

    return (
        <div>
            {step === 1 && (
                <form className="transfer-form" onSubmit={handleNextStep}>
                    {/* Job type selection and file upload form */}
                    <div className="job-type-container">
                        <div
                            className={`job-type-button ${selectedJobType === 'rotoscope' ? 'selected' : ''}`}
                            onClick={() => setSelectedJobType('rotoscope')}
                        >
                            <div className="icon">
                                <img src={ScissorsIcon} alt="Scissors" />
                            </div>
                            <div className="title">ROTOSCOPE</div>
                            <div className="description">Example: separating objects from the background</div>
                            <div className="price-container">
                                <span className="price">$2.5</span>
                                <span className="approx">Approx. per frame</span>
                            </div>
                        </div>
                        <div
                            className={`job-type-button ${selectedJobType === 'cleanup' ? 'selected' : ''}`}
                            onClick={() => setSelectedJobType('cleanup')}
                        >
                            <div className="icon">
                                <img src={EraserIcon} alt="Eraser" />
                            </div>
                            <div className="title">CLEANUP</div>
                            <div className="description">Example: removing unwanted objects from the scene</div>
                            <div className="price-container">
                                <span className="price">$2.5</span>
                                <span className="approx">Approx. per frame</span>
                            </div>
                        </div>
                    </div>

                    <div 
                        className={`file-upload-container ${isDragging ? 'dragging' : ''}`}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onClick={() => document.getElementById('file-upload').click()}
                    >
                        {files.length === 0 ? (
                            <div className="file-upload-label">
                                <img src={CloudUploadIcon} alt="Upload" className="cloud-upload-icon" />
                                <div className="file-upload-text">NOTICE: Only Upload The Frames You Want Worked On!</div>
                                {/* NOTE: Keep this code for later use if needed:
                                <div className="notice-text">NOTICE: Only Upload The Frames You Want Worked On!</div>
                                */}
                            </div>
                        ) : (
                            <div className="file-list-container">
                                {files.map((fileObj, index) => (
                                    <div key={index} className="file-item">
                                        <div className="file-thumbnail">
                                            {fileObj.thumbnail ? (
                                                <img src={fileObj.thumbnail} alt="Thumbnail" />
                                            ) : (
                                                <img src={PlaceholderImage} alt="Placeholder" className="placeholder" />
                                            )}
                                        </div>
                                        <div className="file-info">
                                            <span className="file-name   file-name-step1">{fileObj.file.name}</span>
                                            <div className="progress-container">
                                                <div className="progress-bar">
                                                    <div 
                                                        className="progress-bar-fill" 
                                                        style={{width: `${fileObj.progress}%`}}
                                                    ></div>
                                                </div>
                                                <span className="progress-text">{fileObj.progress}%</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* Add More Files CTA */}
                                <div className="add-more-files" onClick={handleAddMoreFiles}>
                                    + Add more files
                                </div>
                            </div>
                        )}
                        <input 
                            type="file" 
                            id="file-upload" 
                            onChange={handleUpload} 
                            multiple 
                            className="file-upload-input"
                            accept=".mp4, .webm, .ogg, .mov, .avi, .mkv, .mxf, .flv, .wmv, .mpeg, .mpg, .3gp, .3g2, .mts, .m2ts, .ts, .vob"
                        />
                    </div>

                    <div className="tooltip-container">
                        <button 
                            type="submit" 
                            className="submit-button" 
                            disabled={!areAllFilesUploaded() || isSubmitting || files.length === 0}
                        >
                            {isSubmitting ? 'Processing...' : 'Next'}
                        </button>
                        {(!areAllFilesUploaded() || files.length === 0) ? (
                            <span className="tooltip-text">
                                {getNextButtonTitle()}
                            </span>
                        ) : null}
                    </div>
                </form>
            )}

            {step === 2 && (
                <form className="transfer-form" onSubmit={handleSubmit}>
                    <h2>Describe the job and confirm your details</h2>
                    <div className="file-list-container-step2">
                        {files.map((fileObj, index) => (
                            <div key={index} className="file-item">
                                <div className="file-thumbnail">
                                    {fileObj.thumbnail ? (
                                        <img src={fileObj.thumbnail} alt="Thumbnail" />
                                    ) : (
                                        <img src={PlaceholderImage} alt="Placeholder" className="placeholder" />
                                    )}
                                </div>
                                <div className="file-info">
                                    <span className="file-name">{fileObj.file.name}</span>
                                    <input
                                        type="text"
                                        placeholder="Description"
                                        value={fileDetails[index]?.description || ''}
                                        onChange={(e) => handleFileDetailChange(index, 'description', e.target.value)}
                                        className="file-description-input"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="form-inputs-wrapper">
                        <div className="left-inputs">
                            <input
                                type="text"
                                name="fullName"
                                value={formValues.fullName}
                                onChange={handleChange}
                                placeholder="Name"
                                required
                                className="full-width-input"
                            />
                            <input
                                type="email"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                                placeholder="Email"
                                required
                                className="full-width-input"
                            />
                        </div>
                        <div className="right-input">
                            <textarea
                                name="extraInfo"
                                value={formValues.extraInfo}
                                onChange={handleChange}
                                placeholder="Extra Info"
                                className="extra-info full-width-input"
                            />
                        </div>
                    </div>
                    <div className="terms-container">
                        <input
                            type="checkbox"
                            id="terms"
                            checked={isTermsChecked}
                            onChange={handleTermsChange}
                        />
                        <label htmlFor="terms">
                            I agree to the <a href="/terms-of-service.html" target="_blank">terms of service</a>
                        </label>
                    </div>
                    <button 
                        type="submit" 
                        className="submit-button lets-rock-button"
                        disabled={!isTermsChecked} // Disable button if terms are not checked
                    >
                        Submit
                    </button>
                </form>
            )}

            {step === 3 && (
                <form className="transfer-form">
                    <h2>Thank You!</h2>
                    <p>
                        Your form has been successfully submitted! We will review your request and get back to you with a price shortly.
                    </p>
                    <p>
                        We appreciate your patience, and we're excited to collaborate with you!
                    </p>
                </form>
            )}
        </div>
    );
};

export default TransferForm;
